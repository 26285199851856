import React from "react";

function Feature() {
  return (
    <div className="feature">
      <h1>Site features you want to include.</h1>
    </div>
  );
}

export default Feature;
