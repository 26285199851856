import React from "react";

function Conversions() {
  return (
    <div className="conversions">
      <div className="new_banner3">
        <div className="banner_text">
          <h4 className="subhead">CONVERSION CHART</h4>
          <h2 className="mainhead">SOME SLOGAN</h2>
        </div>
      </div>
      <h1>Conversion Charts Page</h1>
    </div>
  );
}

export default Conversions;
